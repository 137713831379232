.has-validator:focus {
  outline: 0 !important;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color) !important;
}

[__invalid] {
  --bs-border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
  --bs-border-opacity: 1 !important;
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)) !important;
  border-color: var(--bs-danger) !important;
}

[__validator_error]::after {
  display: inline-block;
  font-size: 0.85em;
  color: var(--bs-danger);
  content: attr(__validator_error);
  margin-top: 0.25em;
}