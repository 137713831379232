.dropzone {
  border: dashed 4px rgb(var(--bs-secondary-rgb)) !important;
  background-color: #f2f6fc;
  border-radius: var(--bs-border-radius) !important;


  &.highlight {
    border: dashed 4px rgb(var(--bs-primary-rgb)) !important;
  }
}

.dropzone .dropzone-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #8c96a8;
  z-index: 20;
}

.dropzone .file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}

.file-icon {
  font-size: 60px;
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: 8px 0px;
}

.hr-sect:before,
.hr-sect:after {
  content: "";
  flex-grow: 1;
  background: #ddd;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}