#loadingIndicator {
  display: none;
}

#loadingIndicator > div {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#loadingIndicator > div:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, .5);
}

.loadingIndicator2 {
  --duration: 1.5s;
  --container-size: 150px;
  --box-size: 22px;
  --box-border-radius: 10%;

  width: var(--container-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.loadingIndicator2 .box {
  width: var(--box-size);
  height: var(--box-size);
  position: relative;
  display: block;
  transform-origin: -50% center;
  border-radius: var(--box-border-radius);

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: lightblue;
    border-radius: var(--box-border-radius);
    box-shadow: 0px 0px 10px 0px rgba(#1C9FFF, 0.4);
  }

  &:nth-child(1) {
    animation: slide var(--duration) ease-in-out infinite alternate;

    &:after {
      animation: color-change var(--duration) ease-in-out infinite alternate;
    }
  }

  @for $i from 1 to 5 {
    &:nth-child(#{$i + 1}) {
      animation: flip-#{$i} var(--duration) ease-in-out infinite alternate;

      &:after {
        animation: squidge-#{$i} var(--duration) ease-in-out infinite alternate;
      }
    }
  }

  &:nth-child(2):after {
    background-color: #1C9FFF;
  }

  &:nth-child(3):after {
    background-color: #1FB1FD;
  }

  &:nth-child(4):after {
    background-color: #22C7FB;
  }

  &:nth-child(5):after {
    background-color: #23D3FB;
  }
}

@keyframes slide {
  0% {
    background-color: #1795FF;
    transform: translatex(0vw);
  }

  100% {
    background-color: #23D3FB;
    transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25)));
  }
}

@keyframes color-change {
  0% {
    background-color: #1795FF;
  }

  100% {
    background-color: #23D3FB;
  }
}

@for $i from 1 to 5 {
  @keyframes flip-#{$i} {

    0%, #{$i * 15}% {
      transform: rotate(0);
    }

    #{$i * 15 + 20}%, 100% {
      transform: rotate(-180deg);
    }
  }

  @keyframes squidge-#{$i} {
    #{$i * 15 - 10}% {
      transform-origin: center bottom;
      transform: scalex(1) scaley(1);
    }

    #{$i * 15}% {
      transform-origin: center bottom;
      transform: scalex(1.3) scaley(0.7);
    }

    #{$i * 15 + 10}%,
    #{$i * 15 + 5}% {
      transform-origin: center bottom;
      transform: scalex(0.8) scaley(1.4);
    }

    #{$i * 15 + 40}%, 100% {
      transform-origin: center top;
      transform: scalex(1) scaley(1);
    }

    #{$i * 15 + 25}% {
      transform-origin: center top;
      transform: scalex(1.3) scaley(0.7);
    }
  }
}

.loadingIndicator3 {
  font-size: 20vmin;
  font-family: monospace;
  font-weight: bold;
  text-transform: uppercase;
  color: #0000;
  -webkit-text-stroke: 2px var(--bs-primary);
  --g:conic-gradient(var(--bs-primary) 0 0) no-repeat text;
  background:var(--g) 0,var(--g) 1ch,var(--g) 2ch,var(--g) 3ch,var(--g) 4ch,var(--g) 5ch,var(--g) 6ch;
  animation: 
    l20-0 1.5s linear infinite alternate,
    l20-1 3s   linear infinite;
}

.loadingIndicator3:before {
  content: "Loading";
}

@keyframes l20-0 {
  0%  {background-size: 1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   }
  25% {background-size: 1ch 100%,1ch 50% ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 50% ,1ch 100%}
  50% {background-size: 1ch 100%,1ch 100%,1ch 50% ,1ch 0   ,1ch 50% ,1ch 100%,1ch 100%}
  75% {background-size: 1ch 100%,1ch 100%,1ch 100%,1ch 50% ,1ch 100%,1ch 100%,1ch 100%}
  to  {background-size: 1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%}
}
@keyframes l20-1 {
  0%,50%    {background-position-y: 100%}
  50.01%,to {background-position-y: 0   }
}