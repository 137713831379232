.cm-editor {
  height: 100%;
  font-variant-ligatures: none;

  &.cm-focused {
    outline: 0 none;
    box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(17, 99, 206, 0.25);
    border-radius: var(--bs-border-radius);
  }

  .cm-content {
    font-size: 13px;
    font-family: "JetBrains Mono";
  }

  .cm-gutters {

    .cm-gutter {
      font-size: 13px;
      font-family: "JetBrains Mono";
    }

    .cm-foldGutter {
      width: 2px;

      span[title="Fold line"] {
        display: none;
      }

    }
    
  }

}