#two-factor-auth {
  max-width: 270px;
}

.two-factor-auth-step {
  display: grid;
  gap: 10px;
  grid-template-columns: 150px auto;
}

.two-factor-auth-step img {
  max-width: 128px;
  max-height: 128px;
}