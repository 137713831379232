.glow-box {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #060c21;
}

.glow-box:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #fff;
  z-index: -1;
  border-radius: 0.35rem;
}

.glow-box:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #fff;
  z-index: -2;
  filter: blur(3px);
  border-radius: 0.35rem;
}

.glow-box:nth-child(1):before,
.glow-box:nth-child(1):after {
  background: linear-gradient(235deg, #2ccf50, #5f9ea0, #125ce6);
}

.glow-box .content {
  padding: 0.45rem;
  color: #fff;
  text-align: end;
  display: flex;
  gap: 1rem;
}

.circular-progress {
  position: relative;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: conic-gradient(var(--data-plan-color) var(--data-plan-percentage), #ededed 0deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular-progress::before {
  content: "";
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fff;
}

.progress-value {
  position: relative;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--bs-primary);
}