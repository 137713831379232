.context-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: #{$zindex-modal - 1};
}

.context-menu.expanded {
  height: 100%;
}

.context-menu.expanded .context-menu-item:nth-child(2) {
  transform: translateY(-80px);
}

.context-menu.expanded .context-menu-item:nth-child(3) {
  transform: translateY(-140px);
}

.context-menu.expanded .context-menu-item:nth-child(4) {
  transform: translateY(-200px);
}

.context-menu.expanded .context-menu-item:nth-child(5) {
  transform: translateY(-260px);
}

.context-menu.expanded .context-menu-item:nth-child(6) {
  transform: translateY(-320px);
}

.context-menu .main-menu-item {
  position: relative;
  height: 70px;
  width: 70px;
  background-color: var(--bs-primary);
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
}

.context-menu .main-menu-item::before {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: inherit;
  border-radius: 0 0 10px 0;
  z-index: -1;
}

.context-menu .main-menu-item .context-menu-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.context-menu .context-menu-item {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  background-color: var(--bs-primary);;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.context-menu .context-menu-item:hover {
  cursor: pointer;
}

.context-menu .context-menu-item a {
  position: relative;
}

.context-menu .context-menu-item a .caption {
  display: none;
  position: absolute;
  right: 100%;
  top: calc(50% - 0.5em - $badge-padding-y/2);
  white-space: nowrap;
}

.context-menu.expanded .context-menu-item a .caption {
  display: block;
}