.template-item {  
  width: 220px;
  height: calc(220px + var(--bs-body-line-height) + $spacer);
  text-align: center;

  .template-thumbnail {
    --bs-border-color: transparent;

    width: 220px;
    height: 220px;
    position: relative;

    img.thumbnail {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .template-label {
    --bs-border-color: #{$gray-200};
    background-color: #{$gray-200};
    color: #{$black};
  }

  &.selected {

    .template-thumbnail::after {
      border: solid 5px #{$primary};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: " ";
      border-radius: 0.25rem;
      pointer-events: none;
    }

    .template-label {
      --bs-border-color: #{$primary};
      background-color: #{$primary};
      color: #{$white};
    }
  }
}