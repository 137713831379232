.online-indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  --indicator-color: transparent;
  --indicator-color-online: #198754;
  --indicator-color-offline: #adb5bd;

  .online-indicator {
    position: relative;
    width: fit-content;
    background: var(--indicator-color);
    border-radius: 50%;
    width: 1em;
    height: 1em;
    margin: 0.5em;

    .online-indicator-ring {
      position: absolute;
      width: fit-content;
      background-color: var(--indicator-color);
      border-radius: 50%;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      animation: online-indicator-ani 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    }

  }

}

.online-indicator-container2 {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.25em;
  --indicator-color: transparent;
  --indicator-color-online: #198754;
  --indicator-color-offline: #adb5bd;

  .online-indicator {
    position: relative;
    width: fit-content;
    background: var(--indicator-color);
    border-radius: 50%;
    width: 1em;
    height: 1em;
    margin: 0.5em;
    right: calc(-1em * 0.35);
    bottom: calc(-1em * 0.35);

    .online-indicator-ring {
      position: absolute;
      width: fit-content;
      background-color: var(--indicator-color);
      border-radius: 50%;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      animation: online-indicator-ani 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    }

  }

  .indicator-text::after,
  .indicator-desc::after {
    content: attr(offline);
  }

}

.livechat-online .indicator-text::after,
.online-indicator-container.online .indicator-text::after,
.livechat-online .indicator-desc::after,
.online-indicator-container.online .indicator-desc::after {
  content: attr(online);
}

.livechat-offline .indicator-text::after,
.online-indicator-container.offline .indicator-text::after,
.livechat-offline .indicator-desc::after,
.online-indicator-container.offline .indicator-desc::after {
  content: attr(offline);
}

.livechat-online .online-indicator,
.online-indicator.online {
  --indicator-color: var(--indicator-color-online);
}

.livechat-offline .online-indicator,
.online-indicator.offline {
  --indicator-color: var(--indicator-color-offline);

  .online-indicator-ring {
    animation: none;
  }
}

@keyframes online-indicator-ani {

  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}