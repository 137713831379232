.required:focus {
  outline: 0 !important;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color) !important;
}

[__required] {
  --bs-border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
  --bs-border-opacity: 1 !important;
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity)) !important;
  border-color: var(--bs-warning) !important;
}