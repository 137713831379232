$pb_grid-breakpoints: (
  desktop: 99999px,
  tablet: 992px,
  mobile: 576px
);

$pb-grid-columns: 12;

$pb-grid-gutter-width: 1.5px; //!! Yêu cầu phải có .5
$pb-grid-row-columns: 6;

@mixin pbGridSystem() {

  @each $infix, $breakpoint in $pb_grid-breakpoints {

    @container section-container (width <=#{$breakpoint}) {
      @for $i from 1 through $pb-grid-columns {
        .pb-block-#{$infix}-#{$i} {
          width: #{($i/$pb-grid-columns*100)}cqw !important;
          flex: 0 0 auto;
        }
      }
    }
  }

}