.hr-1 {
  height: 1px;
}

.hr-2 {
  height: 2px;
}

.hr-3 {
  height: 3px;
}

.hr-4 {
  height: 4px;
}

.hr-5 {
  height: 5px;
}

.arrow-box {
  --arrow-bg: var(--bs-info-bg-subtle);
  --arrow-border: var(--bs-info-border-subtle);

  position: relative;
  background: var(--arrow-bg) !important;
}

.arrow-box-warning {
  --arrow-bg: var(--bs-warning-bg-subtle);
  --arrow-border: var(--bs-warning-border-subtle);
}

.arrow-box:after,
.arrow-box:before {
  right: calc(100% - var(--bs-border-width));
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: var(--arrow-bg) !important;
  border-width: 6px;
  margin-top: -6px;
}

.arrow-box:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: var(--arrow-border) !important;
  border-width: 7px;
  margin-top: -7px;
}


#pageBuilderWrapper {

  .drop-shadow {
    filter: drop-shadow(0.5px 2px 3px #9e9e9f);
  }

  #pageBuilderScrollBox {
    overflow-x: visible !important;
    overflow-y: clip !important;
    background-color: white;
    border-top-left-radius: 0 !important;

    #pageBuilder {
      --pb-gutter-x: #{$pb-grid-gutter-width};
      --pb-gutter-y: #{$pb-grid-gutter-width};
      --pb-block-padding: 3px;
      --pb-block-border: 1px dashed var(--bs-warning);
      --pb-element-padding: 3px;
      --pb-element-border: 1px dashed var(--bs-success);

      .pb-section-wrapper:has([selected]) {
        position: relative;
        // Do section-seperator-line có border-2
        [selected]::after {
          top: 0px;
        }
      }

      &.hide-border-dash-lines {
        --pb-gutter-x: 0;
        --pb-gutter-y: 0;
        --pb-block-padding: 0;
        --pb-block-border: 0 none;
        --pb-element-padding: 0;
        --pb-element-border: 0 none;

        .section-seperator-line {
          display: none;
        }

        .pb-section-wrapper:has([selected]) {
          [selected]::after {
            top: 0; // revert top: 2px; ở phía trên
          }
        }
      }

      [selected]::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: " ";
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(90deg, var(--bs-info) 50%, transparent 50%), linear-gradient(90deg, var(--bs-info) 50%, transparent 50%), linear-gradient(0deg, var(--bs-info) 50%, transparent 50%), linear-gradient(0deg, var(--bs-info) 50%, transparent 50%);
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
        background-size: 15px 1px, 15px 1px, 1px 15px, 1px 15px;
        background-position: left top, right bottom, left bottom, right top;
        animation: border-dance 1s infinite linear;

        @keyframes border-dance {
          0% {
            background-position: left top, right bottom, left bottom, right top;
          }

          100% {
            background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
          }
        }
      }

      &.hide-border-dash-lines .pb-block[selected],
      &.hide-border-dash-lines .pb-element[selected] {
        --pb-block-border: 0 none !important;
        --pb-element-border: 0 none !important;
      }

      .hfbv {
        background-color: var(--bs-success-bg-subtle) !important;
      }

      .stbv {
        background-color: var(--bs-warning-bg-subtle) !important;
      }

      .pb-section {
        container-type: inline-size;
        container-name: section-container;
        display: flex;
        flex-wrap: wrap;

        margin-top: calc(var(--pb-gutter-x));
        margin-bottom: calc(var(--pb-gutter-x));
        margin-right: calc(var(--pb-gutter-x));
        margin-left: calc(var(--pb-gutter-x));
        // margin-right: calc(-0.5 * var(--pb-gutter-x));
        // margin-left: calc(-0.5 * var(--pb-gutter-x));

        @include pbGridSystem;

        .pb-block {
          padding-top: calc(var(--pb-gutter-y) * 0.5);
          padding-bottom: calc(var(--pb-gutter-y) * 0.5);
          padding-right: calc(var(--pb-gutter-x) * 0.5);
          padding-left: calc(var(--pb-gutter-x) * 0.5);

          &.empty {
            min-height: 30px;
          }

          &[selected] {
            --pb-block-border: 1px dashed transparent;
          }

          .pb-block-content {
            display: flex;
            gap: 3px;
            flex-wrap: wrap;
            border: var(--pb-block-border);
            padding: var(--pb-block-padding);

            &.empty {
              min-height: 100%;
            }

            .pb-element {
              min-width: 20px;
              border: var(--pb-element-border);
              position: relative;

              &[selected] {
                --pb-element-border: 1px dashed transparent;
              }

              &.wysiwyg {
                p:last-child {
                  margin-bottom: 0;
                }

                inline-token {
                  display: inline-block;
                  background-color: #D6F0FF;
                  padding: 0 3px;
                  color: #44719B;
                  border-radius: 2px;
                  font-family: "SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace;
                  font-size: 0.9375em;
                }
              }

              &.button-group {
                .btn.br::after {
                  content: ' ';
                  width: 100%;
                }
              }

              .form-label {
                font-weight: 700;
                color: inherit;
              }

              img {
                max-width: 100%;
              }
            }
          }

        }


      }

      #pb-context-menu {
        .breadcrumb {
          display: inline-flex;
          flex-wrap: nowrap;
        }

        .breadcrumb-item+.breadcrumb-item::before {
          float: none !important;
        }
      }

    }

  }

  .title-bar-wrapper {
    .title-bar {
      @include titlbar;
    }

    &+div {
      border-top-right-radius: 0 !important;
    }
  }

  #inspector {
    filter: drop-shadow(0.5px 2px 3px #9e9e9f);

    .category-caption {
      &+div {
        display: none;
      }

      &.expanded+div {
        display: block;
      }
    }

    .form-select {
      background-position: right 0.25rem center !important;
      padding: 0.15rem 1.5rem 0.15rem 0.25rem !important;
    }

  }

}