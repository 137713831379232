.bs-popover-top > .popover-arrow, 
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width) + 1px) !important;
}

.bs-popover-start > .popover-arrow, 
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width) + 1px) !important;
}

.campaignsIndexPopover {
  --bs-popover-max-width: 100vw;
}