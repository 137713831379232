.app-content {
  padding: $lte-content-padding-y $lte-content-padding-x 50px;
}

.app-content-top-area,
.app-content-bottom-area{
  display: flex;
  align-items: center;
  padding: $lte-content-padding-y $lte-content-padding-x;
  overflow: auto;
  background: $lte-app-footer-bg;

  > .row {
    display: contents;
  }

  &.app-content-top-area-sm,
  &.app-content-bottom-area-sm{
    min-height: $lte-app-content-bottom-area-height-sm;
  }

  &.app-content-top-area-md,
  &.app-content-bottom-area-md{
    min-height: $lte-app-content-bottom-area-height-md;
  }
  &.app-content-top-area-lg,
  &.app-content-bottom-area-lg{
    min-height: $lte-app-content-bottom-area-height-lg;
  }
  &.app-content-top-area-xl,
  &.app-content-bottom-area-xl{
    min-height: $lte-app-content-bottom-area-height-xl;
  }
}

.app-content-top-area:not(.app-content-top-area-sm):not(.app-content-top-area-md):not(.app-content-top-area-lg):not(.app-content-top-area-xl),
.app-content-bottom-area:not(.app-content-bottom-area-sm):not(.app-content-bottom-area-md):not(.app-content-bottom-area-lg):not(.app-content-bottom-area-xl) {
  padding-top: $grid-gutter-width * .625;
  padding-bottom: $grid-gutter-width * .625;
}

.app-content-bottom-area {
  border-top: $lte-app-footer-border-top;
}
.app-content-top-area {
  border-bottom: $lte-app-footer-border-top;

  + .app-main {
    padding-top: $grid-gutter-width * .5;
  }
}

