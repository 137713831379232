.modal.fade:not(.show).left .modal-dialog {
  transform: translate3d(-25%, 0, 0);
}

.modal.fade:not(.show).right .modal-dialog {
  transform: translate3d(25%, 0, 0);
}

.modal.fade:not(.show).bottom .modal-dialog {
  transform: translate3d(0, 25%, 0);
}

.modal.left,
.modal.right {
  z-index: #{$zindex-modal + 10};
}

.modal.right .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;

}

.modal.left .modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;

}

.modal.left .modal-dialog.modal-sm {
  max-width: 300px;
}

.modal.left .modal-content,
.modal.right .modal-content {
  min-height: 100vh;
  border: 0;
}

.modal-backdrop + .modal-backdrop {
  z-index: #{$zindex-modal + 20};
}