$_grid-breakpoints: (
  xxl: 1400px,  
  xl: 1200px,
  lg: 992px,
  md: 768px,
  sm: 576px,
  xs: 0
);


@each $suffix, $breakpoint in $_grid-breakpoints {
  .max-width-#{$suffix} {
    max-width: #{$breakpoint};
  }
}

.layout {
  container-type: inline-size;
  container-name: layout-container;
  margin-right: calc($container-padding-x * -0.5);
  margin-left: calc($container-padding-x * -0.5);
}

@container layout-container (min-width: 0) {
  .layout .row {
    margin: 0;
  }
}

@container layout-container (min-width: 99999px) {
  @for $i from 1 through $grid-columns {
    .col-#{$i} {
      width: #{($i/12*100)}cqw !important;
    }
  }
}

@each $infix, $breakpoint in $_grid-breakpoints {

  @container layout-container (min-width: #{$breakpoint}) {
    @for $i from 1 through $grid-columns {
      .col-#{$infix}-#{$i} {
        width: #{($i/12*100)}cqw !important;
        flex: 0 0 auto;
      }
    }
  }
}