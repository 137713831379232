.image-container:hover {
        
  .image-container-buttons {
    display: block !important;
  }
}

#quizLiveChatEditorWrapper {

  $grid-color: #e9dfd9;
  $grid-line-size: 1px;

	background-color: rgba(#fff, .5);
	background-image: linear-gradient($grid-color $grid-line-size, transparent $grid-line-size), linear-gradient(90deg, $grid-color $grid-line-size, transparent $grid-line-size), linear-gradient(rgba(150, 150, 150, .3) 1px, transparent 1px), linear-gradient(90deg, rgba(150, 150, 150, .3) 1px, transparent 1px);
	background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
	background-position: #{-$grid-line-size} #{-$grid-line-size}, #{-$grid-line-size} #{-$grid-line-size}, -1px -1px, -1px -1px;


  .moving-board {
    cursor: move;
  }

  .leader-line {
    z-index: 2;
  }

}

#quizLiveChatEditor {
  min-height: 100%;
  min-width: 100%;

  .btn {
    --bs-btn-box-shadow: none !important;
  }

  .live-chat-node {
    position: absolute;
    display: inline-block;
    z-index: 1;

    &.moving {
      z-index: 9;
    }

    &.cta-node,
    &.image-node {
      img {
        max-width: 100%;
      }

    }

    textarea {
      resize: none;
    }

    .drag-handler {
      &:hover::before {
        color: var(--bs-warning) !important;
      }
    }

    .move-handler {
      &.title-bar {
        @include titlbar;
      }
    }

    .live-chat-node-form {
      border-top-right-radius: 0 !important;
    }

  }

}

#quizLiveChatEditorToolbar {
  top: 1em;
  left: 1em;
  width: 51px;
  z-index: 2;
  position: absolute;
}
