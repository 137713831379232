[x-cloak] { display: none !important; }

.low-highlight {
  background: linear-gradient(180deg,transparent 60%,#f9e3b3 60%);
  white-space: nowrap;
}

.low-highlight-80 {
  background: linear-gradient(180deg,transparent 80%,#f9e3b3 80%);
  white-space: nowrap;
}

.dialog {
  position: relative;
  z-index: 10000;
}

.dialog + .modal-backdrop.show {
  z-index: 9999;
}

.dialog-body {
  display: grid;
  grid-template-columns: $h1-font-size auto;
  gap: $grid-gutter-width/2;
}

.dialog-body i {
  font-size: $h1-font-size;
}

.iti-d-block > .iti {
  display: block !important;
}

@mixin custom-border($suffix) {
  
  .border-end-#{$suffix} {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  }

  .border-top-#{$suffix} {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  }

  .border-bottom-#{$suffix} {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  }

  .border-start-#{$suffix} {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  }

  .border-end-#{$suffix}-0 {
    border-right: 0 none !important;
  }

  .border-top-#{$suffix}-0 {
    border-top: 0 none !important;
  }

  .border-bottom-#{$suffix}-0 {
    border-bottom: 0 none !important;
  }

  .border-start-#{$suffix}-0 {
    border-left: 0 none !important;
  }
}

@media screen and (min-width: 0) {
  @include custom-border("xs");
}

@each $suffix, $breakpoint in $container-max-widths {

  @media screen and (min-width: #{$breakpoint}) {
    @include custom-border($suffix);
  }
}

.show-on-hover-container {
  .show-on-hover {
    display: none;
  }

  &:hover .show-on-hover {
    display: block;
  }
}

.bd-callout-warning {
  --bd-callout-color: var(--bs-warning-text-emphasis);
  --bd-callout-bg: var(--bs-warning-bg-subtle);
  --bd-callout-border: var(--bs-warning-border-subtle);
}

.bd-callout {
  --bs-link-color-rgb: var(--bd-callout-link);
  --bs-code-color: var(--bd-callout-code-color);
  padding: 0.75rem 1rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: var(--bd-callout-color, inherit);
  background-color: var(--bd-callout-bg, var(--bs-gray-100));
  border-left: 0.25rem solid var(--bd-callout-border, var(--bs-gray-300));
}