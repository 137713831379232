.ticker-tape-container {
  overflow-x: hidden;
  display: flex;

  .ticker-tape {
    --direction: normal;
    --delay: 0s;
    --iteration-count: infinite;
    --play: running;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    animation: ticker var(--duration) linear var(--delay) var(--iteration-count);
    animation-play-state: var(--play);
    animation-delay: var(--delay);
    animation-direction: var(--direction);
    padding-left: 100%;

    @keyframes ticker {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-100%);
      }
    }

    & > * {
      display: inline-block;
    }
  }

}

.marquee-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  width: var(--width);
  transform: var(--transform);

  &:hover div {
    animation-play-state: var(--pause-on-hover);
  }

  &:active div {
    animation-play-state: var(--pause-on-click);
  }
}

.marquee-overlay {
  position: absolute;
  width: 100%;
  height: 100%;

  @mixin gradient {
    background: linear-gradient(to right, var(--gradient-color), rgb(255, 255, 255, 0));
  }

  &::before,
  &::after {
    @include gradient;
    content: "";
    height: 100%;
    position: absolute;
    width: var(--gradient-width);
    z-index: 2;
    pointer-events: none;
    touch-action: none;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
}

.marquee {
  flex: 0 0 auto;
  min-width: var(--min-width);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.marquee-initial-child-container {
  flex: 0 0 auto;
  display: flex;
  min-width: auto;
  flex-direction: row;
  align-items: center;
}

.marquee-child {
  transform: var(--transform);
}